import React, { useEffect } from 'react';
import { GlobalScrollbar } from 'mac-scrollbar';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import { useSimple } from 'simple-core-state';
import { core, request, IAccount } from './core';
import { StyledThemeWrapper } from './hooks/useTheme';
import { ModalWrapper } from './modals/ModalComponent';
import { RootRouter } from './router/root.router';
import { ContextMenuWrapper } from 'components/ContextMenuWrapper';

export const App = () => {
	const account = useSimple(core.account);
	const selectedTheme = useSimple(core.theme);

	const getAccount = async () => {
		const res = await request<{ account: IAccount }>('GET', 'account');

		if (res?.data) {
			core.account.set(res.data.account);
		}
	};

	useEffect(() => {
		if (account?.id) getAccount();
	}, []);

	return (
		<StyledThemeWrapper>
			<Toaster containerStyle={{ zIndex: 999999999 }} />
			<GlobalScrollbar skin={selectedTheme} />
			<ModalWrapper>
				<BrowserRouter>
					<ContextMenuWrapper>
						<RootRouter />
					</ContextMenuWrapper>
				</BrowserRouter>
			</ModalWrapper>
		</StyledThemeWrapper>
	);
};

export default App;
