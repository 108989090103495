import React from 'react';
import { useTheme } from '@app/hooks';
import { Block } from './Block';
import styled from 'styled-components';

interface ISquareDotIconProps {
	size: number;
	className?: string;
	opacity?: number;
	rotation?: number;
	top?: number | string;
	left?: number | string;
}

export const SquareDotIcon: React.FC<ISquareDotIconProps> = (props) => {
	const { theme } = useTheme();
	return (
		<SquareDotIconBdy
			className={props.className}
			style={{
				height: props.size,
				width: props.size,
				transform: `rotate(${props.rotation || 0}deg)`,
				top: props.top,
				left: props.left,
				position: 'absolute',
				opacity: props.opacity,
			}}
		>
			<Block color={theme.solid} height={props.size / 2.3} width={props.size / 2.3} />
		</SquareDotIconBdy>
	);
};

const SquareDotIconBdy = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgb(194, 107, 216);
	background: linear-gradient(180deg, rgba(194, 107, 216, 1) 0%, rgba(131, 72, 255, 1) 100%);
`;
