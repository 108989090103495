import React, { CSSProperties, useState } from 'react';
import { Block } from './Block';
import { Text } from './Text';
import { useTheme } from '@app/hooks';
import { useSimple } from 'simple-core-state';
import { core } from 'core/core';

interface IAvatarProps {
	accountId: string;
	avatar: string;
	size?: number;
	style?: CSSProperties;
	className?: string;
	displayName?: string;
	onClick?: () => void;
	// If we want to override the src
	src?: string;
}

export const Avatar = (p: IAvatarProps) => {
	const CDN = useSimple(core.cdn_url);
	const { theme } = useTheme();
	const [err, setErr] = useState(false);
	const size = p.size || 24;
	return (
		<>
			{p.src || (!!p.avatar && !err) ? (
				<img
					onClick={p.onClick}
					onError={() => setErr(true)}
					src={p?.src || `${CDN}/account/${p.accountId}/avatar/${p.avatar}`}
					alt={`avatar-${p?.displayName || p.avatar}`}
					className={p?.className}
					style={{ borderRadius: size, height: size, width: size, ...p.style }}
				/>
			) : (
				<Block
					color={theme.solid20}
					class={p?.className + ' items-center justify-center'}
					onClick={p.onClick}
					height={size}
					width={size}
					style={{ borderRadius: size, ...p.style, minWidth: size }}
				>
					<Text medium size={size / 2.3} style={{ opacity: 0.8, userSelect: 'none' }}>
						{p.displayName?.charAt(0)}
					</Text>
				</Block>
			)}
		</>
	);
};
