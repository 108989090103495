import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon, Icons } from './Icon';

interface IconButtonProps {
	icon: Icons;
	size: number;
	backgroundColor?: string;
	hoverColor?: string;
	noBack?: boolean;
	iconColor?: string;
	iconSize?: number;
	style?: CSSProperties;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
	disabled?: boolean;
	className?: string;
}

export const IconButton = (p: IconButtonProps) => {
	return (
		<IconButtonBody
			onClick={p.onClick}
			disabled={p?.disabled}
			hc={p?.hoverColor}
			style={{
				height: p.size,
				width: p.size,
				minWidth: p.size,
				minHeight: p.size,
				backgroundColor: p.noBack ? 'transparent' : p.backgroundColor || 'lightgray',
				borderRadius: p.size,
				...p.style,
			}}
			className={p?.className}
		>
			<Icon name={p.icon} size={p.noBack ? p.size : p.iconSize || p.size / 2.6} color={p?.iconColor || 'black'}></Icon>
		</IconButtonBody>
	);
};

const IconButtonBody = styled.div<{ disabled?: boolean; hc?: string }>`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		opacity: 0.6;
	}
	&:active {
		opacity: 0.4;
	}

	transition: opacity 0.15s ease-in-out;
`;
