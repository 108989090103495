export default `<svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1279_302)">
<path d="M256.001 320C269.301 320 280.001 309.3 280.001 296V168C280.001 154.75 269.251 144 256.901 144C244.551 144 232.001 154.8 232.001 168V296C232.001 309.3 242.801 320 256.001 320ZM256.001 353.1C238.641 353.1 224.561 367.18 224.561 384.54C224.601 401.9 238.601 416 256.001 416C273.401 416 287.441 401.92 287.441 384.56C287.401 367.2 273.401 353.1 256.001 353.1Z" fill="currentColor"/>
<path opacity="0.4" d="M506.301 417L293.001 53C276.671 25 235.461 25 219.021 53L5.82051 417C-10.5895 444.9 9.84951 480 42.7405 480H469.341C502.101 480 522.601 445 506.301 417ZM232.001 168C232.001 154.75 242.751 144 256.001 144C269.251 144 280.001 154.8 280.001 168V296C280.001 309.25 269.251 320 256.901 320C244.551 320 232.001 309.3 232.001 296V168ZM256.001 416C238.641 416 224.561 401.92 224.561 384.56C224.561 367.2 238.631 353.12 256.001 353.12C273.371 353.12 287.441 367.2 287.441 384.56C287.401 401.9 273.401 416 256.001 416Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_1279_302">
<rect width="512" height="512" fill="currentColor"/>
</clipPath>
</defs>
</svg>
`