import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface InputProps {
	value?: string;
	placeholder?: string;
	onTextChange?: (v: string) => void;
	style?: CSSProperties;
	defaultValue?: any;
	disabled?: boolean;
	type?: string;
	small?: boolean;
}

export const Input = (props: InputProps) => {
	const s: React.CSSProperties = {
		...props.style,
		height: props.small ? 34 : '100%',
	};

	return (
		<InputBody
			type={props.type}
			disabled={props?.disabled}
			defaultValue={props?.defaultValue}
			style={props.style}
			placeholder={props.placeholder}
			value={props.value}
			onChange={(v) => {
				if (props?.onTextChange) props.onTextChange(v.currentTarget.value);
			}}
		/>
	);
};

const InputBody = styled.input`
	padding: 0px 12px;
	border-radius: 7px;
	height: 36px;
	display: block;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.solid};
	border: solid 0.1rem ${({ theme }) => theme.solid10};
	font-size: 14px;
	color: ${({ theme }) => theme.text};

	outline: none;

	:focus {
		border: solid 0.1rem ${({ theme }) => theme.solid20};
	}
	::placeholder {
		color: ${({ theme }) => theme.solid30};
		font-size: 14px;
		font-weight: 400;
	}

	transition:
		border 0.2s ease-in-out,
		ease-in-out 0.2s opacity;
`;
