import { core } from './core';
import Axios from 'axios';
import { IAudioNote, IMappedNotes, ModalDataTypes, ModalNames } from './types';

export const openModal = <T extends ModalNames>(name: T, data?: ModalDataTypes[T]) => {
	core._events.modal.send({ name, data });
};

// ! REMOVE THIS ASAP
export const millSecondsToNiceTime = (s: number, config?: { hideMilliseconds?: boolean; hideHours?: boolean }) => {
	let sec_num = s / 1000;
	let hours = Math.floor(sec_num / 3600);
	let minutes = Math.floor((sec_num - hours * 3600) / 60);
	let seconds = Math.floor(sec_num - hours * 3600 - minutes * 60);
	let millSeconds = s.toString().slice(s.toString().length - 3, s.toString().length);

	if (millSeconds === '0') millSeconds = '000';

	return `${config?.hideHours ? '' : (hours <= 9 ? '0' : '') + hours + ':'}${(minutes <= 9 ? '0' : '') + minutes}:${
		(seconds <= 9 ? '0' : '') + seconds
	}${config?.hideMilliseconds ? '' : '.' + millSeconds}`;
};

export const getTimeAgo = (date: Date): string => {
	const now = new Date();
	const elapsedMillis = now.getTime() - date.getTime();

	const elapsedSeconds = Math.floor(elapsedMillis / 1000);
	const elapsedMinutes = Math.floor(elapsedMillis / (1000 * 60));
	const elapsedHours = Math.floor(elapsedMillis / (1000 * 60 * 60));
	const elapsedDays = Math.floor(elapsedMillis / (1000 * 60 * 60 * 24));
	const elapsedMonths = Math.floor(elapsedMillis / (1000 * 60 * 60 * 24 * 30));

	if (elapsedSeconds < 60) {
		return formatTimeAgo(elapsedSeconds, 'second');
	} else if (elapsedMinutes < 60) {
		return formatTimeAgo(elapsedMinutes, 'minute');
	} else if (elapsedHours < 24) {
		return formatTimeAgo(elapsedHours, 'hour');
	} else if (elapsedDays < 30) {
		return formatTimeAgo(elapsedDays, 'day');
	} else {
		return formatTimeAgo(elapsedMonths, 'month');
	}
};

const formatTimeAgo = (value: number, unit: string): string => {
	if (value === 1) {
		return `1 ${unit} ago`;
	} else {
		return `${value} ${unit}s ago`;
	}
};

export const queryParamsToObject = (text: string): any => {
	let urlParams: any = {};
	var match,
		pl = /\+/g, // Regex for replacing addition symbol with a space
		search = /([^&=]+)=?([^&]*)/g,
		decode = function (s: any) {
			return decodeURIComponent(s.replace(pl, ' '));
		},
		query = text.substring(1);

	urlParams = {};

	while ((match = search.exec(query))) {
		urlParams[decode(match[1])] = decode(match[2]);
	}

	return urlParams;
};

export const downloadFileFromUrl = async (url: string, file_name: string): Promise<boolean> => {
	try {
		Axios({
			method: 'GET',
			url: url,
			responseType: 'blob',
		}).then((response) => {
			// create file link in browser's memory
			const href = URL.createObjectURL(response.data);

			// create "a" HTML element with href to file & click
			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', file_name); //or any other extension
			document.body.appendChild(link);
			link.click();

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		});

		return true;
	} catch (error) {
		return false;
	}
};

export const notesToMappedByUser = (input: IAudioNote[]) => {
	let a: IMappedNotes = {};

	const ids = [...new Set(input.map((e) => e.account_id))];

	for (let item of ids) {
		a[item] = [];
	}

	for (let item of input) {
		a[item.account_id].push(item);
	}

	return a;
};
