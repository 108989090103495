import React, { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Block, Button, Input, Txt } from '@app/components';
import { IModalProps, ModalComponent } from './ModalComponent';
import { core, IAudioFile, ModalDataTypes, request } from '@app/core';
import { useSimple } from 'simple-core-state';
import { useTheme } from '@app/hooks';

export const CreateFolderModal = (p: IModalProps<ModalDataTypes['CreateFolder']>) => {
	const { theme } = useTheme();
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const folders = useSimple(core.folder_items);

	const createFolder = useCallback(async () => {
		setLoading(true);
		const res = await request<IAudioFile>(
			'POST',
			'folder/create/' + (!!p.data?.intoFolderId ? p.data?.intoFolderId : ''),
			{
				data: {
					name,
				},
			},
		);
		setLoading(false);

		if (res.data) {
			let arr = [...folders.root];
			arr.push(res.data);

			core.folder_items.patch({ root: arr });

			toast.success('Folder has been created');
			p.onClose();
		} else {
			toast.error(res.message);
		}
	}, [name, p.data, folders]);

	return (
		<ModalComponent {...p}>
			<Block width={350}>
				<Block>
					<Txt marginBottom={10} h3>
						Name your new folder
					</Txt>
					<Input onTextChange={setName} value={name} placeholder="Studio recordings" />
				</Block>
				<Block className="row item-center mt-[15px]">
					<Button
						disabled={!confirm}
						color={theme.solid100}
						text={'Cancel'}
						style={{ marginRight: 15 }}
						onClick={() => {
							p.onClose();
						}}
					/>
					<Button color={theme.solid100} text={'Create'} onClick={() => createFolder()} disabled={!name && !loading} />
				</Block>
			</Block>
		</ModalComponent>
	);
};
