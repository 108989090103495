import React, { forwardRef, CSSProperties, useMemo } from 'react';
import styled from 'styled-components';
import { normilizeValues } from './helper';
import { IBlockProps } from '@app/core';

export const Block = forwardRef((p2: IBlockProps, ref: any) => {
	const p = useMemo(() => normilizeValues<IBlockProps>(p2), [p2, ref]);
	const { style, height, width, minHeight, minWidth, maxHeight, maxWidth } = p;

	const s: CSSProperties = {
		...(p.borderRadius && { borderRadius: p.borderRadius }),
		...(p.color && { backgroundColor: p.color }),
		...(p.padding && { padding: p.padding }),
		...(p.paddingTop && { paddingTop: p.paddingTop }),
		...(p.paddingLeft && { paddingLeft: p.paddingLeft }),
		...(p.paddingRight && { paddingRight: p.paddingRight }),
		...(p.paddingBottom && { paddingBottom: p.paddingBottom }),
		...(p.margin && { margin: p.margin }),
		...(p.marginTop && { marginTop: p.marginTop }),
		...(p.marginLeft && { marginLeft: p.marginLeft }),
		...(p.marginRight && { marginRight: p.marginRight }),
		...(p.marginBottom && { marginBottom: p.marginBottom }),
		...(p.justifyContent && { justifyContent: p?.justifyContent }),
		display: 'flex',
		height,
		width,
		minHeight,
		minWidth,
		maxHeight,
		maxWidth,
		...style,
	};
	return (
		<BlockBody
			className={p?.class || p?.className + ' flex flex-col'}
			style={s}
			onClick={p?.onClick}
			$btn={p?.button}
			ref={ref}
			id={p?.id}
			{...p?.extraProps}
		>
			{p.children}
		</BlockBody>
	);
});

const BlockBody = styled.div<{ $btn?: boolean }>`
	${({ $btn }) => ($btn ? 'cursor: pointer;' : '')}
	justify-content: column;

	&:hover {
		${({ $btn }) => ($btn ? 'opacity: 0.5;' : '')}
	}
	&:active {
		${({ $btn }) => ($btn ? 'opacity: 0.3;' : '')}
	}
`;
