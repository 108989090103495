import React, { CSSProperties } from 'react';
import { Block } from './Block';
import styled from 'styled-components';

interface IDividerProps {
	color?: string;
	style?: CSSProperties;
	width?: string;
	m?: number;
	vertical?: boolean;
	size?: number;
}

export const Divider = (p: IDividerProps) => {
	const SIZE = p?.size || 1;
	return (
		<B
			color={p?.color || 'white'}
			height={p.vertical ? p.width || '100%' : SIZE}
			width={p.vertical ? SIZE : p.width || '100%'}
			style={{ ...p?.style, margin: !p.vertical ? `${p.m}px 0px` : `0px ${p.m}px`, borderRadius: SIZE }}
		></B>
	);
};

const B = styled(Block)`
	transition: background-color 0.2s ease-in-out;
	flex-shrink: 0;
`;
