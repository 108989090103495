import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSimple } from 'simple-core-state';
import styled from 'styled-components';
import { Button, Txt, Block } from '@app/components';
import { ModalDataTypes, core, request } from '@app/core';
import { IModalProps, ModalComponent } from './ModalComponent';

export const RenameAudioFileModal = (p: IModalProps<ModalDataTypes['RenameAudioFile']>) => {
	const appState = useSimple(core.app_state);
	const myFiles = useSimple(core.files);
	const folderItems = useSimple(core.folder_items);
	const [newName, setNewName] = useState('');
	const [loading, setLoading] = useState(false);

	const updateName = useCallback(async () => {
		console.log(p.data);

		setLoading(true);
		let a = folderItems.root;

		let i1 = a.indexOf(a.filter((e) => e.id === p.data.id)[0]);
		a[i1].display_name = newName;

		console.log('newList', a);

		const res = await request('PATCH', `audio/${p.data.id}`, {
			data: {
				new_name: newName,
			},
		});

		setLoading(false);

		if (res.data) {
			core.folder_items.patch({ root: a });
			// core.app_state.updatePiece('', a);
			core.app_audio_data.patch({ display_name: newName });
			p.onClose();
			toast.success(res.message);
		} else {
			toast.error(res.message);
		}
	}, [newName, p.data, myFiles]);

	const a = useCallback(() => {
		setNewName(p.data?.current_name || '');
	}, [p.data]);

	useEffect(() => {
		a();
	}, [p.data]);

	return (
		<ModalComponent {...p}>
			<Block width={250}>
				<Txt h2 marginBottom={8}>
					Rename File
				</Txt>
				<RenameInput onChange={(e) => setNewName(e.currentTarget.value)} value={newName} />
				<Button
					style={{ width: '100%' }}
					text="Update"
					onClick={() => updateName()}
					disabled={newName === p.data?.current_name || newName === '' || loading}
				/>
				<Button style={{ width: '100%', marginTop: 10 }} text="Close" color="#3e3e3e" onClick={() => p.onClose()} />
			</Block>
		</ModalComponent>
	);
};

const RenameInput = styled.input`
	background-color: #1f1f1f;
	border-radius: 8px;
	padding: 12px;
	font-size: 16px;
	color: white;
	border: none;
	margin-bottom: 30px;
	outline: #343434;
`;
