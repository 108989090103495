import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
	percentage: number;
	style?: CSSProperties;
}

/**
 *
 * @param p.percentage a value from 0 - 100
 * @returns
 */
export const ProgressBar = (p: ProgressBarProps) => {
	return (
		<ProgressBarBody style={p?.style}>
			<InnerBlock className="progressBar-inner" p={p.percentage}></InnerBlock>
		</ProgressBarBody>
	);
};

const ProgressBarBody = styled.div`
	display: flex;
	background-color: ${({ theme }) => theme.solid100};
	width: 100%;
	height: 12px;
	border-radius: 20px;
	padding: 4px;

	@keyframes move-it {
		0% {
			background-position: initial;
		}
		100% {
			background-position: 100px 0px;
		}
	}

	.progressBar-inner {
		background: repeating-linear-gradient(
			${({ theme }) => `45deg, ${theme.solid100}, ${theme.solid100} 5%, ${theme.solid40} 5%, ${theme.solid40} 10%`}
		);
		background-size: 100px 100px;
		animation: move-it 5s linear infinite;
	}
`;

const InnerBlock = styled.div<{ p: number }>`
	background-color: ${({ theme }) => theme.solid20};
	height: 100%;
	border-radius: 20px;
	width: ${({ p }) => p}%;
	transition: width 0.1s ease-in-out;
`;
