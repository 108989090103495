import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSimple } from 'simple-core-state';
import styled from 'styled-components';
import {
	Avatar,
	Block,
	Button,
	CustomTooltip,
	Divider,
	IconButton,
	LoadingSpinner,
	SelectBox,
	TextButton,
	Txt,
} from '../components';
import { IModalProps, ModalComponent } from './ModalComponent';
import { useTheme } from '@app/hooks';
import dayjs from 'dayjs';
import { IAccessType, IPendingInviteType, IShare, IUser, ModalDataTypes, core, openModal, request } from '@app/core';

export const AudioShareModal = (props: IModalProps<ModalDataTypes['AudioShare']>) => {
	const p = props.data;
	const { theme } = useTheme();

	const appState = useSimple(core.app_state);
	const folderItems = useSimple(core.folder_items);

	const [shareInfo, setShareInfo] = useState<IShare | null>();
	const [pendingInvites, setPendingInvites] = useState<IPendingInviteType[]>([]);
	const [loaded, setLoaded] = useState(false);

	const enableShareLink = useCallback(async () => {
		const res = await request<IShare>('POST', `audio/${p.id}/share`);

		if (res.data) {
			let f = folderItems;

			const x1 = f[appState.selected_folder || 'root'].filter((e) => e.id === p.id)[0];
			const x2 = f[appState.selected_folder || 'root'].indexOf(x1);

			f[appState.selected_folder || 'root'][x2].share_id = res.data.id;

			core.folder_items.set(f);
			setShareInfo(res.data);

			toast.success('Successfull enabled sharing');
		} else {
			toast.error(res.message);
		}
	}, [p, folderItems, shareInfo, appState]);

	const toggleJoinable = useCallback(
		async (shareId?: string) => {
			const res = await request('POST', `share/${shareId}/toggle`);
			if (res.data) {
				toast.success(!shareInfo?.open ? 'Successfull unlocked' : 'Successfull locked');
				// @ts-ignore
				setShareInfo((prev) => ({ ...prev, open: !prev.open }));
			} else {
				toast.error(res.message);
			}
		},
		[shareInfo],
	);

	const getShareInfo = async (shareId: string) => {
		const res = await request<{ pending_invites: IPendingInviteType[]; users: any[]; (...args: any) }>(
			'GET',
			`share/${shareId}`,
		);
		if (res.data) {
			setPendingInvites(res.data.pending_invites);
			setShareInfo(res.data);
			setLoaded(true);
		}
	};

	const openInviteByEmailModal = useCallback(async () => {
		console.log(p);

		if (!p.share_id) return;

		openModal('ShareSessionInvite', {
			share_session_id: p.share_id,
		});
	}, [p.share_id]);

	const handleRevokeAccess = useCallback(
		async (inviteID: string) => {
			openModal('Warning', {
				callback: async () => {
					const res = await request('DELETE', `share/${p.share_id}/invite/${inviteID}`);
					if (res.data) {
						toast.success(res.message);
						removeInviteFromList(inviteID);
					} else {
						toast.error(res.message);
					}
				},
				cancelBtnTxt: 'Cancel',
				confirmBtnTxt: 'Delete',
				description: 'this will revoke the invetation that you have created.',
				headingTitle: 'Are you sure you want to revoke',
				wait3seconds: true,
			});
		},
		[p],
	);

	const removeInviteFromList = useCallback(
		(inviteID: string) => {
			let a = [...pendingInvites];

			const ix = a.indexOf(a.filter((e) => e.id === inviteID)[0]);
			a.splice(ix, 1);

			setPendingInvites(a);
		},
		[pendingInvites],
	);

	const copyPublicLink = useCallback(() => {
		window.navigator.clipboard.writeText(
			`${import.meta.env.MODE === 'production' ? 'https://streambk.com' : 'http://localhost:3000'}/share/${
				shareInfo.invite_code
			}`,
		);

		toast.success('Link copied to clipboard');
	}, [shareInfo]);

	useEffect(() => {
		if (p?.share?.id) {
			setShareInfo(p.share);
			setLoaded(true);
		} else if (p?.share_id) {
			getShareInfo(p.share_id);
		} else {
			setLoaded(true);
		}
	}, []);

	return (
		<ModalComponent
			className="w-full max-w-[530px]"
			onClose={props.onClose}
			openState={props.openState}
			onAfterClose={props.onClose}
		>
			<Block className="h-full min-h-[420px] flex-col justify-between">
				<Block className=" row items-baseline whitespace-nowrap">
					<Txt bold size={22}>
						Sharing options
					</Txt>
					<Txt
						size={16}
						marginLeft={6}
						color={theme.solid40}
						style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
					>
						{p?.display_name}
					</Txt>
				</Block>
				<Block className="h-full flex-col">
					{!loaded ? (
						<Block className="height-[200px] items-center justify-center">
							<LoadingSpinner color={theme.solid80} size={30} />
						</Block>
					) : !!shareInfo ? (
						<Block marginTop={20} height={200}>
							<Block className="row items-center justify-between">
								<Txt smallHeading>Collaborators</Txt>
								<CustomTooltip
									key_name="access-info"
									content={`Enable or disable user access to the file '${p.display_name}'`}
								>
									<Txt size={14} color="#949494" mr={10}>
										Access
									</Txt>
								</CustomTooltip>
							</Block>
							<ParticipantsList
								usersList={shareInfo.users}
								shareID={shareInfo.id}
								onUsersListUpdate={(X) => {
									setShareInfo((e) => ({ ...e, users: X }));
								}}
							/>
							<PendingInvitesList data={pendingInvites} onRevokeAccess={handleRevokeAccess} />
						</Block>
					) : (
						<Block className="height-[200px] mt-[20px] h-full items-center justify-center">
							<Txt medium center style={{ maxWidth: 380 }}>
								You audio has not been shared, click below to enable sharing and configure you're collaborators
							</Txt>
							<Button
								text="Enable"
								color={theme.solid100}
								onClick={() => enableShareLink()}
								style={{ width: 'fit-content', marginTop: 10 }}
							/>
						</Block>
					)}
				</Block>

				<Block className="row h-fit justify-between">
					{shareInfo?.id ? (
						<Block className="item-center row">
							<Button
								text="Copy invite link"
								onClick={copyPublicLink}
								color={theme.solid100}
								textColor={theme.solid}
								className="mr-2.5"
							/>
							{/* <Block height={40} color={theme.solid5} borderRadius={50} vCenter>
								<Txt
									className=" overflow-hidden text-ellipsis whitespace-nowrap"
									size={12}
									pl={15}
									pr={15}
									color={theme.solid60}
									medium
								>
									streambk.com/share/{shareInfo.invite_code}
								</Txt>
							</Block> */}
							{/* <IconButton
								icon="clipboard"
								size={40}
								iconSize={17}
								backgroundColor={theme.solid10}
								iconColor={theme.solid60}
								onClick={copyPublicLink}
								style={{ marginLeft: 8 }}
							/> */}
							<IconButton
								icon={shareInfo?.open ? 'lock_open' : 'lock_closed'}
								size={36}
								iconSize={15}
								style={{ borderRadius: 48, marginLeft: 8 }}
								backgroundColor={shareInfo?.open ? '#579757' : '#ef5353'}
								iconColor={shareInfo?.open ? '#ffffff' : theme.solid}
								onClick={() => toggleJoinable(shareInfo?.id)}
							/>
						</Block>
					) : (
						<Block></Block>
					)}
					<Block className="row">
						{!!shareInfo && (
							<Button
								text="Invite by email"
								onClick={openInviteByEmailModal}
								color={theme.solid100}
								textColor={theme.solid}
								className="mr-2.5"
							/>
						)}
						<Button
							text="Close"
							onClick={() => {
								props.onClose();
							}}
							color={theme.solid5}
							textColor={theme.solid90}
						/>
					</Block>
				</Block>
			</Block>
		</ModalComponent>
	);
};

const UsersList = styled.div`
	margin-top: 10px;
	height: 100%;
`;

interface IPendingInviteListType {
	onRevokeAccess: (id: string) => void;
	data: IPendingInviteType[];
}

const PendingInvitesList = ({ data, onRevokeAccess }: IPendingInviteListType) => {
	const { theme } = useTheme();

	if (!data?.length) return;
	else
		return (
			<Block mt={30}>
				<Txt smallHeading mb={8}>
					Pending outgoing invites
				</Txt>
				<Block>
					{data.map((item, index) => {
						return (
							<Block className="row mb-[15px] items-center" key={index} borderRadius={10}>
								<Block width="100%">
									<Block className="mb-[5px[ row">
										<Txt normal>{item.email}</Txt>
									</Block>
									<Block className="row">
										<Txt normal>{dayjs(item.created_at).format('YYYY/MM/DD - HH:MMa')}</Txt>
									</Block>
								</Block>
								<Block width="100%">
									<Divider color={theme.solid10} />
								</Block>
								<TextButton
									text="revoke"
									bold
									color="#E83838"
									onClick={() => {
										onRevokeAccess(item.id);
									}}
									style={{ marginLeft: 10 }}
								/>
							</Block>
						);
					})}
				</Block>
			</Block>
		);
};

interface IParticipantListType {
	shareID: string;
	usersList: IUser[];
	onUsersListUpdate: (e: IUser[]) => void;
}

const ParticipantsList = (props: IParticipantListType) => {
	const [loading, setLoading] = useState(false);

	const { theme } = useTheme();

	const toggleAccess = useCallback(
		async (shareUserID: string, access: IAccessType) => {
			setLoading(true);

			const res = await request('PATCH', `share/${props.shareID}/${shareUserID}`, { data: { access_type: access } });
			setLoading(false);
			if (res.data) {
				if (!props.usersList) return;
				let list = [...props.usersList];

				let l1 = list.findIndex((e) => e.id === shareUserID);
				list[l1].access_type = access;

				props.onUsersListUpdate(list);
				toast.success(res.message);
			} else {
				toast.error(res.message);
			}
		},
		[props.shareID, props.usersList],
	);

	return (
		<UsersList>
			{!props?.usersList?.length && (
				<Block>
					<Txt center color={theme.solid50}>
						No joined users
					</Txt>
				</Block>
			)}
			{props?.usersList?.map((item, index) => (
				<Block
					key={index}
					className="row mb-[5px] items-center justify-between pb-[5px] pt-[5px]"
					borderRadius={12}
					width="calc(100% - 10px)"
				>
					<Block className="row items-center">
						<Avatar accountId={item.id} avatar={item.avatar} displayName={item.display_name} size={35} />
						<Block marginLeft={10}>
							{item?.display_name ? (
								<Txt medium>{item.display_name}</Txt>
							) : (
								<Txt color={theme.solid70} size={14}>
									e Name not set
								</Txt>
							)}
							<Txt color={theme.solid70} marginTop={2}>
								{item.email}
							</Txt>
						</Block>
					</Block>

					<Block>
						{item?.access_type && (
							<SelectBox
								value={item.access_type}
								onChange={(e) => {
									toggleAccess(item.id, e);
								}}
								disabled={loading}
								list={[
									['No access', 'NONE'],
									['Can edit', 'READ/WRITE'],
									['Can view', 'READ'],
								]}
							/>
						)}
					</Block>
				</Block>
			))}
		</UsersList>
	);
};
