import React, { CSSProperties } from 'react';
import { PlacesType, Tooltip } from 'react-tooltip';

interface ICustomTooltipProps {
	children: React.ReactNode;
	key_name: string;
	content: string;
	disabled?: boolean;
	style?: CSSProperties;
	outerStyle?: CSSProperties;
	pos?: PlacesType;
}

export const CustomTooltip = ({
	disabled,
	children,
	key_name,
	content,
	outerStyle,
	pos,
	style,
}: ICustomTooltipProps) => {
	return (
		<>
			{disabled ? (
				<div style={outerStyle}>{children}</div>
			) : (
				<div data-tooltip-content={content} data-tooltip-place={pos || 'top'} id={key_name} style={outerStyle}>
					{children}
				</div>
			)}

			<Tooltip
				data-tooltip-id={key_name}
				variant="dark"
				delayShow={0}
				style={{
					backgroundColor: '#323232',
					fontSize: 12,
					padding: 8,
					borderRadius: 5,
					zIndex: 501,
					opacity: 1,
					fontFamily: 'Outfit',
					fontWeight: 600,
					letterSpacing: '2%',
					...style,
				}}
			/>
		</>
	);
};
