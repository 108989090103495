import { GlobalRoutes } from '@app/core';
import { useNavigate } from 'react-router-dom';

export const useGoto = () => {
	const nav = useNavigate();
	// const history = useHistory();

	const back = () => {
		nav(-1);
	};

	const goto = (r: `${GlobalRoutes}`, params?: { [index: string]: string }) => {
		// Check if we need to replace path params
		if (params !== undefined) {
			let n = r as string;
			for (const item of Object.entries(params)) {
				n = n.replace(':' + item[0], item[1]);
			}
			nav(n);
		} else {
			nav(r);
		}
	};

	return { goto, back };
};
