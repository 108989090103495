import { CSSProperties } from 'react';

export const normilizeValues = <T extends CSSProperties>(input: { [key: string]: any }): T => {
	let obj = Object.assign({}, input);

	// margin
	if (obj?.mt) obj.marginTop = input.mt;
	if (obj?.mr) obj.marginRight = input.mr;
	if (obj?.mb) obj.marginBottom = input.mb;
	if (obj?.ml) obj.marginLeft = input.ml;

	// padding
	if (obj?.pt) obj.paddingTop = input.pt;
	if (obj?.pr) obj.paddingRight = input.pr;
	if (obj?.pb) obj.paddingBottom = input.pb;
	if (obj?.pl) obj.paddingLeft = input.pl;

	return obj as T;
};
