export interface ThemeObject {
	// Base variables
	name: string;

	// App colors
	app_background: string;
	app_sidebar_border: string;

	// text is black, solid = white and solid100 = 'black'
	text: string;
	solid: string;
	solid1: string;
	solid2: string;
	solid3: string;
	solid4: string;
	solid5: string;
	solid6: string;
	solid7: string;
	solid8: string;
	solid9: string;
	solid10: string;
	solid15: string;
	solid20: string;
	solid25: string;
	solid30: string;
	solid40: string;
	solid50: string;
	solid60: string;
	solid70: string;
	solid75: string;
	solid80: string;
	solid85: string;
	solid90: string;
	solid91: string;
	solid92: string;
	solid93: string;
	solid94: string;
	solid95: string;
	solid96: string;
	solid97: string;
	solid98: string;
	solid99: string;
	solid100: string;
}

export type IThemeNames = 'light' | 'dark';

export const Themes: {
	[K in IThemeNames]: ThemeObject;
} = {
	light: {
		name: 'light',
		app_background: '#070707',
		app_sidebar_border: '#F2F2F2',
		text: '#000000',
		// ------------
		solid: '#FFFFFF',
		solid1: '#FCFCFC',
		solid2: '#FAFAFA',
		solid3: '#F7F7F7',
		solid4: '#F5F5F5',
		solid5: '#F2F2F2',
		solid6: '#F0F0F0',
		solid7: '#EDEDED',
		solid8: '#E8E8E8',
		solid9: '#E8E8E8',
		solid10: '#E8E8E8',
		solid15: '#D9D9D9',
		solid20: '#CCCCCC',
		solid25: '#BFBFBF',
		solid30: '#B2B2B2',
		solid40: '#999999',
		solid50: '#808080',
		solid60: '#666666',
		solid70: '#4D4D4D',
		solid75: '#404040',
		solid80: '#333333',
		solid85: '#262626',
		solid90: '#1A1A1A',
		solid91: '#171717',
		solid92: '#141414',
		solid93: '#121212',
		solid94: '#0F0F0F',
		solid95: '#0D0D0D',
		solid96: '#0A0A0A',
		solid97: '#080808',
		solid98: '#050505',
		solid99: '#030303',
		solid100: '#000000',
	},
	dark: {
		name: 'dark',
		app_background: '#070707',
		app_sidebar_border: '#0D0D0D',
		text: '#FFFFFF',
		// ------------
		solid: '#000000',
		solid1: '#030303',
		solid2: '#050505',
		solid3: '#080808',
		solid4: '#0A0A0A',
		solid5: '#0D0D0D',
		solid6: '#0F0F0F',
		solid7: '#121212',
		solid8: '#141414',
		solid9: '#171717',
		solid10: '#1A1A1A',
		solid15: '#262626',
		solid20: '#333333',
		solid25: '#404040',
		solid30: '#4D4D4D',
		solid40: '#666666',
		solid50: '#808080',
		solid60: '#999999',
		solid70: '#B2B2B2',
		solid75: '#BFBFBF',
		solid80: '#CCCCCC',
		solid85: '#D9D9D9',
		solid90: '#E5E5E5',
		solid91: '#E8E8E8',
		solid92: '#EBEBEB',
		solid93: '#EDEDED',
		solid94: '#F0F0F0',
		solid95: '#F2F2F2',
		solid96: '#F5F5F5',
		solid97: '#F7F7F7',
		solid98: '#FAFAFA',
		solid99: '#FCFCFC',
		solid100: '#FFFFFF',
	},
};

export const WaveplayerColors = {
	default: {
		color: '#4c80f2',
		fadedColor: '#26427f',
	},
};
