import { TreeItemData } from '@atlaskit/tree/dist/types/types';
import { SimpleCore } from 'simple-core-state';
import { ICoreType } from './types';

type ItemId = any;

interface TreeData {
	rootId: ItemId;
	items: { [index: string]: TreeItem };
}

type TreeItem = {
	id: ItemId;
	children: Array<ItemId>;
	hasChildren?: boolean;
	isExpanded?: boolean;
	isChildrenLoading?: boolean;
	data?: TreeItemData;
};

const Instance = new SimpleCore<ICoreType>(
	{
		
		seats: [],
		// New folder structure
		folderTree: {
			items: {},
			rootId: '',
		},
		// Creating Note
		createNote: {
			creatorEnabled: false,
			showDraft: false,
			timeframe: [0],
			message: '',
			attachments: [],
		},
		// TODO: Make sure this will be the new state info for the wave player system that we created as a V3 version
		wavePlayer: {
			isPaused: true,
			isMuted: false,
			currentTime: 0,
			totalDuration: 0,
			volume: 0,
		},

		wavePlayerMeta: {
			audio: null,
			mappedNotes: {},
			focusedNote: null,
			replyIntoMessage: '',
		},

		websocket: {
			connected: false,
			connection_failed: false,
			ws_user_token: '',
		},
		theme: 'light',
		sound_muted: false,
		sound_volume: 1,
		api_url: 'api.streambk.com',
		cdn_url: `https://cdn.streambk.com`,
		show_into_guide: false,
		account: null,
		account_notifications: [],
		appSidebarOpen: false,
		app_state: {
			current_path: '/app/account',
			// todo: remove old reply into
			selectedReplyInto: null,
			selected_folder: '',
			selected_folder_obj: {
				account_id: '',
				created_at: '',
				description: '',
				id: '',
				name: 'My files',
				parent_folder_id: '',
				childrens: [],
			},
			selected_folder_title: 'My files',
			folders: [],
		},
		app_audio_data: null,
		// This is required for the dashboard and file based system
		folder_items: { root: [], shared: [] },
		files: [],
		drag_selected_folders: [],
	},
	{ storage: { prefix: 'streambk' } },
);

Instance.persist(['account', 'wavePlayer', 'theme', 'appSidebarOpen']);

Instance.events.create(['modal', 'contextMenu', 'getPlayerInfo']);

export const core = Instance.core();
