import { useTheme } from '@app/hooks';
import React from 'react';
import { Txt } from '.';
import styled from 'styled-components';

interface IBetaBannerProps {}

export const BetaBanner: React.FC<IBetaBannerProps> = () => {
	const { theme } = useTheme();
	return (
		<BannerAlert>
			<Txt medium size={13} color={theme.solid} center>
				An updated layout has been released, and we are working hard to fix bugs and add more functionality.
			</Txt>
			<Txt color={theme.solid} medium size={13} ml={4} style={{ opacity: 0.6 }}>
				(We are still in beta :)
			</Txt>
		</BannerAlert>
	);
};

const BannerAlert = styled.div`
	background-color: ${({ theme }) => theme.solid100};
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	flex-direction: row;
`;
