import React, { CSSProperties, useMemo } from 'react';
import { Block, Txt } from '.';
import { useTheme } from '@app/hooks';
import styled from 'styled-components';

interface ITimecodeTextProps {
	// time in milliseconds
	time: number;
	// time_in_custom: number;
	show_milliseconds?: boolean;
	showTimeIndicator?: boolean;
	enable_show_zerocount?: boolean;
	size?: number;
	style?: CSSProperties;
}

const SPACING = 2;
const FONT_SIZE = 14;

export const TimecodeText: React.FC<ITimecodeTextProps> = ({
	time,
	show_milliseconds,
	showTimeIndicator,
	enable_show_zerocount,
	size,
	style,
}) => {
	const { theme } = useTheme();

	const { hours, minutes, s, milliseconds } = useMemo(() => {
		let milliseconds = Math.max(0, time);
		const x1 = time / 1000;
		let seconds = Math.floor(x1) % 60;
		const x2 = x1 / 60;
		let minutes = Math.floor(x2) % 60;

		let hours = Math.floor(x2 / 60) % 24;

		return {
			s: seconds,
			minutes,
			hours,
			milliseconds,
		};
	}, [time]);

	const Between = (c?: string) => (
		<Txt medium marginRight={SPACING} color={c || theme.text}>
			:
		</Txt>
	);

	const BdyStyleAppend: CSSProperties = { ...style, ...{ alignContent: 'center' } };

	return (
		<B size={size || FONT_SIZE} className="row" style={BdyStyleAppend}>
			{hours !== (enable_show_zerocount ? -1 : 0) && (
				<>
					<Txt
						medium
						marginRight={SPACING}
						color={theme[hours === 0 ? 'solid40' : 'solid100']}
						style={{ fontVariantNumeric: 'tabular-nums' }}
					>
						{(hours < 10 ? '0' : '') + hours}
						{!showTimeIndicator || 'h'}
					</Txt>
					{Between(theme[hours === 0 ? 'solid40' : 'solid100'])}
				</>
			)}

			{minutes !== (enable_show_zerocount ? -1 : 0) && (
				<>
					<Txt
						medium
						marginRight={SPACING}
						color={theme[minutes === 0 ? 'solid40' : 'solid100']}
						style={{ fontVariantNumeric: 'tabular-nums' }}
					>
						{(minutes < 10 ? '0' : '') + minutes}
						{!showTimeIndicator || 'm'}
					</Txt>
					{Between(theme[minutes === 0 ? 'solid40' : 'solid100'])}
				</>
			)}
			<Txt medium style={{ fontVariantNumeric: 'tabular-nums' }}>
				{(s < 10 ? '0' : '') + s}
				{!showTimeIndicator || 's'}
			</Txt>
			{show_milliseconds && (
				<Block className="row" marginLeft={SPACING}>
					{Between()}
					<Txt medium style={{ fontVariantNumeric: 'tabular-nums' }}>
						{milliseconds / 10}
					</Txt>
				</Block>
			)}
		</B>
	);
};

const B = styled(Block)<{ size: number }>`
	p {
		font-size: ${({ size }) => size}px;
	}
`;
