export default `<svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M21.6792 20.1827C21.1466 20.1827 20.7148 19.7509 20.7148 19.2185V0.964233C20.7148 0.431746 21.1466 0 21.6792 0C22.2118 0 22.6436 0.431746 22.6436 0.964233V19.2189C22.6436 19.7509 22.2118 20.1827 21.6792 20.1827Z" fill="currentColor"/>
<path d="M25.146 15.9078C24.6134 15.9078 24.1816 15.476 24.1816 14.9435V5.1322C24.1816 4.59971 24.6134 4.16797 25.146 4.16797C25.6786 4.16797 26.1104 4.59971 26.1104 5.1322V14.9435C26.1104 15.476 25.6786 15.9078 25.146 15.9078Z" fill="currentColor"/>
<path d="M28.5835 13.7607C28.0509 13.7607 27.6191 13.329 27.6191 12.7965V7.29236C27.6191 6.75987 28.0509 6.32812 28.5835 6.32812C29.1161 6.32812 29.5479 6.75987 29.5479 7.29236V12.7965C29.5479 13.329 29.1161 13.7607 28.5835 13.7607Z" fill="currentColor"/>
<path d="M32.0347 12.6731C31.5021 12.6731 31.0703 12.2414 31.0703 11.7089V8.61267C31.0703 8.08018 31.5021 7.64844 32.0347 7.64844C32.5672 7.64844 32.999 8.08018 32.999 8.61267V11.7089C32.999 12.2414 32.5672 12.6731 32.0347 12.6731Z" fill="currentColor"/>
<path d="M7.86866 18.7359C7.3361 18.7359 6.9043 18.3042 6.9043 17.7717V3.15564C6.9043 2.62315 7.3361 2.19141 7.86866 2.19141C8.40121 2.19141 8.83301 2.62315 8.83301 3.15564V17.7717C8.8326 18.3042 8.40121 18.7359 7.86866 18.7359Z" fill="currentColor"/>
<path d="M11.3374 13.3564C10.8049 13.3564 10.373 12.9247 10.373 12.3922V8.42712C10.373 7.89464 10.8049 7.46289 11.3374 7.46289C11.87 7.46289 12.3018 7.89464 12.3018 8.42712V12.3922C12.3014 12.9247 11.8696 13.3564 11.3374 13.3564Z" fill="currentColor"/>
<path d="M14.7749 16.6834C14.2424 16.6834 13.8105 16.2516 13.8105 15.7191V5.11462C13.8105 4.58214 14.2424 4.15039 14.7749 4.15039C15.3075 4.15039 15.7393 4.58214 15.7393 5.11462V15.7191C15.7389 16.252 15.3071 16.6834 14.7749 16.6834Z" fill="currentColor"/>
<path d="M18.2261 13.7451C17.6935 13.7451 17.2617 13.3134 17.2617 12.7809V7.27673C17.2617 6.74425 17.6935 6.3125 18.2261 6.3125C18.7586 6.3125 19.1904 6.74425 19.1904 7.27673V12.7809C19.19 13.3138 18.7582 13.7451 18.2261 13.7451Z" fill="currentColor"/>
<path d="M0.964359 12.4536C0.431803 12.4536 0 12.0218 0 11.4893V8.62829C0 8.09581 0.431803 7.66406 0.964359 7.66406C1.49692 7.66406 1.92872 8.09581 1.92872 8.62829V11.4893C1.92872 12.0218 1.49692 12.4536 0.964359 12.4536Z" fill="currentColor"/>
<path d="M4.43311 14.5689C3.90055 14.5689 3.46875 14.1372 3.46875 13.6047V6.43689C3.46875 5.9044 3.90055 5.47266 4.43311 5.47266C4.96567 5.47266 5.39747 5.9044 5.39747 6.43689V13.6051C5.39706 14.1376 4.96567 14.5689 4.43311 14.5689Z" fill="currentColor"/>
</g>
</svg>
`