import { useTheme } from '@app/hooks';
import React, { useCallback, useState } from 'react';
import ReactModal from 'react-modal';
import { useSimpleEvent } from 'simple-core-state';
import styled from 'styled-components';
import { AudioShareModal } from './AudioShareModal';
import { CreateFolderModal } from './CreateFolderModal';
import { RenameAudioFileModal } from './RenameAudioFile';
import { UploadAudioModal } from './UploadAudioModal';
import { WarningModal } from './WarningModal';
import { ShareSessionInviteModal } from './ShareSessionInviteModal';
import { ModalNames, core } from '@app/core';

export interface IModalProps<T> {
	openState: boolean;
	onClose: () => void;
	data: T;
}

export interface IModalComponentProps {
	openState: boolean;
	onClose: () => void;
	onAfterClose?: () => void;
	children: React.ReactNode;
	className?: string;
}

export const ModalComponent = (p: IModalComponentProps) => {
	const { theme } = useTheme();

	return (
		<ReactModalBox
			isOpen={p.openState}
			onRequestClose={() => p.onClose()}
			onAfterClose={p.onAfterClose}
			shouldCloseOnOverlayClick
			closeTimeoutMS={200}
			shouldCloseOnEsc={false}
			ariaHideApp={false}
			className={p.className}
			style={{
				overlay: {
					backgroundColor: theme.name === 'light' ? '#00000025' : '#ffffff26',
					zIndex: 999999,
					backdropFilter: 'blur(4px)',
				},
			}}
		>
			<ModalInsideContainer>{p.children}</ModalInsideContainer>
		</ReactModalBox>
	);
};

const ReactModalBox = styled(ReactModal)`
	background-color: ${({ theme }) => theme.solid};
	border: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	padding: 20px;
	display: flex;
	align-items: center;
	outline: none;
	max-width: 550px;

	@media only screen and (max-width: 500px) {
		width: calc(100%);
		border-radius: 0px;
		height: calc(100%);
		background-color: transparent;
	}
`;

const ModalInsideContainer = styled.div`
	height: calc(100%);
	width: 100%;

	@media only screen and (max-width: 500px) {
		background-color: ${({ theme }) => theme.solid};
		align-items: center;
		width: 100%;
		padding: 20px 20px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
`;

interface ModalEventData {
	name: ModalNames;
	data: any;
}

interface IModalWrapperProps {
	children?: React.ReactNode;
}

export const ModalWrapper = (p: IModalWrapperProps) => {
	const [openStore, setOpenStore] = useState<Partial<{ [K in ModalNames]: { open: boolean; data: any } }>>({});

	const handleOpen = useCallback(
		(e: ModalEventData) => {
			setOpenStore((v) => {
				v[e.name] = {
					data: e.data,
					open: true,
				};
				return v;
			});
		},
		[openStore],
	);

	useSimpleEvent(core._events.modal, (e: ModalEventData) => {
		console.log('Modal event: ', e);
		handleOpen(e);
	});

	const closeModal = useCallback(
		(modalName: string) => {
			setTimeout(() => {
				let a = { ...openStore };
				delete a[modalName];

				setOpenStore(a);
			}, 10);
		},
		[openStore],
	);

	return (
		<>
			{openStore.AudioShare?.open === true && (
				<AudioShareModal
					data={openStore.AudioShare.data}
					onClose={() => closeModal('AudioShare')}
					openState={openStore.AudioShare.open}
				/>
			)}
			{openStore.ShareSessionInvite?.open === true && (
				<ShareSessionInviteModal
					data={openStore.ShareSessionInvite.data}
					onClose={() => closeModal('ShareSessionInvite')}
					openState={openStore.ShareSessionInvite.open}
				/>
			)}
			{openStore.RenameAudioFile?.open === true && (
				<RenameAudioFileModal
					data={openStore.RenameAudioFile.data}
					onClose={() => closeModal('RenameAudioFile')}
					openState={openStore.RenameAudioFile.open}
				/>
			)}
			{openStore.CreateFolder?.open === true && (
				<CreateFolderModal
					data={openStore.CreateFolder.data}
					onClose={() => closeModal('CreateFolder')}
					openState={openStore.CreateFolder.open}
				/>
			)}
			{openStore.UploadAudio?.open === true && (
				<UploadAudioModal
					data={openStore.UploadAudio.data}
					onClose={() => closeModal('UploadAudio')}
					openState={openStore.UploadAudio.open}
				/>
			)}
			{openStore.Warning?.open === true && (
				<WarningModal
					data={openStore.Warning.data}
					onClose={() => closeModal('Warning')}
					openState={openStore.Warning.open}
				/>
			)}
			{p.children}
		</>
	);
};
