
import React, { CSSProperties } from 'react';
import * as icons from '../assets/icons';
import SvgInline from 'react-inlinesvg'

interface IIconProps {
	name: Icons;
	size?: number;
	color?: string;
	style?: CSSProperties;
	id?: string;
	className?: string;
}

export type Icons = 'angle_left' | 'arc' | 'arrow_line' | 'backword_10' | 'bell' | 'carrot_left' | 'chart_network' | 'chart_tree_map' | 'chat' | 'check' | 'circle_user' | 'clipboard' | 'clock' | 'comment' | 'discord' | 'drive' | 'eclipse' | 'eye' | 'folder_outline' | 'folder_person_outline' | 'folder_person' | 'folder' | 'forward_10' | 'grip_dots' | 'image' | 'inbox' | 'link' | 'linkedin' | 'lock_closed' | 'lock_open' | 'logo' | 'magic' | 'mic' | 'moon' | 'network' | 'paperclip' | 'pause' | 'pen' | 'person_outline' | 'person' | 'play_pause' | 'play' | 'plus_thick' | 'plus' | 'reply' | 'share_dots' | 'share' | 'sidebar_toggle' | 'sliders' | 'speaker_muted' | 'speaker' | 'star' | 'sun' | 'trash' | 'triangle' | 'twitter' | 'upload' | 'volume_slash' | 'warn' | 'wave' | 'wrench' | 'x' | 'xmark' ;

export const Icon = (props: IIconProps) => {
	const s = props?.size || 20;
	return (
    <SvgInline
			className={(props?.className ? props.className + ' ' : '') + 'icon-' + props.name}
      src={icons[props.name]}
      height={s}
      width={s}
      color={props.color}
      style={props?.style}
			id={props?.id}
    />
  );
};