import { CSSProperties, ReactNode, useMemo } from 'react';
import { useTheme } from '@app/hooks';
import styled from 'styled-components';
import { normilizeValues } from './helper';
import { MarginStyle, PaddingStyle } from '@app/core';

interface TextProps extends PaddingStyle, MarginStyle {
	className?: string;
	children?: ReactNode;
	color?: string;
	size?: number;
	style?: CSSProperties;
	center?: boolean;
	italic?: boolean;
	medium?: boolean;
	extraBold?: boolean;
	bold?: boolean;
	normal?: boolean;
	h1?: boolean;
	h2?: boolean;
	h3?: boolean;
	weight?: string;
	w?: string;
	smallHeading?: boolean;

	onClick?: () => void;
	letterSpacing?: string;
}

export const Text = (p2: TextProps) => {
	const { theme } = useTheme();

	// memorize props
	const p = useMemo(() => normilizeValues<TextProps>(p2), [p2, theme]);

	const s: CSSProperties = {
		// Apply first the h-series, than we can modify the weight after with med or bold...
		...(p?.h1 && { fontSize: p?.size || 30, fontWeight: 800 }),
		...(p?.h2 && { fontSize: p?.size || 24, fontWeight: 700 }),
		...(p?.h3 && { fontSize: p?.size || 20, fontWeight: 600 }),
		// Weights...
		...(p?.medium && { fontWeight: '600' }),
		...(p?.extraBold && { fontWeight: '800' }),
		...(p?.bold && { fontWeight: '700' }),
		...(p?.normal && { fontWeight: '500' }),
		...(p?.italic && { fontStyle: 'italic' }),
		...((p?.weight || p.w) && { fontWeight: p.weight || p.w }),

		...(p?.center && { textAlign: 'center' }),
		...(p?.size && { fontSize: p.size }),
		...(p?.letterSpacing && { letterSpacing: p.letterSpacing }),
		...(p?.padding && { padding: p.padding }),
		...(p?.paddingTop && { paddingTop: p.paddingTop }),
		...(p?.paddingLeft && { paddingLeft: p.paddingLeft }),
		...(p?.paddingRight && { paddingRight: p.paddingRight }),
		...(p?.paddingBottom && { paddingBottom: p.paddingBottom }),
		...(p?.margin && { margin: p.margin }),
		...(p?.marginTop && { marginTop: p.marginTop }),
		...(p?.marginLeft && { marginLeft: p.marginLeft }),
		...(p?.marginRight && { marginRight: p.marginRight }),
		...(p?.marginBottom && { marginBottom: p.marginBottom }),
		...{ color: p.color || theme.text },
		...(p?.smallHeading && {
			bold: true,
			fontSize: 17,
			fontWeight: '700',
			letterSpacing: '-0.1px',
			color: theme.solid70,
		}),
		...p?.style,
	};

	return (
		<P style={s} className={p.className} onClick={p.onClick}>
			{p2.children}
		</P>
	);
};

const P = styled.p`
	transition: color 0.2s ease-in-out;
`;
