import Axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { core } from './core';
import { IFolderType } from './types';
import { TreeData } from '@atlaskit/tree';

interface IRequestOptions {
	data?: any;
	headers?: { [key: string]: any };
	onUploadProgress?: (e: AxiosProgressEvent) => void;
}

interface UploadProgress {
	loaded: number;
	total: number;
	totalSize: number;
	positon: number;
	timeStamp: number;
}

// If host not found fallback to default localhost
const MACHINE_HOST = location?.host.split(':')[0] || 'localhost';

export const CDN_URL = import.meta.env.MODE === 'production' ? 'https://cdn.streambk.com/' : import.meta.env.CDN_URL;
export const BASE_URL =
	import.meta.env.MODE === 'production' ? 'https://api.streambk.com/' : `http://${MACHINE_HOST}:3020/`;

export const request = async <T extends any>(
	method: 'POST' | 'GET' | 'DELETE' | 'PATCH',
	path: string,
	options?: IRequestOptions,
): Promise<{ data: T; success: boolean; message: string }> => {
	try {
		const res = await Axios({
			method: method,
			url: BASE_URL + path,
			data: options?.data,
			headers: options?.headers,
			withCredentials: true,
			onUploadProgress: options?.onUploadProgress,
		});

		// console.log(`/${path}`, res.data);

		return res.data;
	} catch (error: any) {
		console.log('ERROR =>', error);
		if (error?.response?.data?.data?.logout === true) {
			core.account.reset();
		}

		return error.response.data as { data: T; success: boolean; message: string };
		// TODO:This is most likely a server error that didn't reach the server
	}
};

export const formatBytes = (bytes: number, decimals = 2) => {
	if (!+bytes) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export interface TreeOption<T extends object> {
	/**
	 */
	id: keyof T;
	/**
	 */
	children: keyof T;
}

type ReType<T, K extends string> = T & { [P in K]?: ReType<T, K>[] };

/**
 * @param list
 * @param options
 */
export function listToTree<
	T extends object,
	C extends Pick<TreeOption<T>, 'id'> & {
		parentId: keyof T;
		children: string & keyof R;
	},
	R extends ReType<T, C['children']>,
>(list: T[], options: C): R[] {
	const res: R[] = [];
	const fullMap = new Map<T[C['id']], T>(list.map((v) => [v[options.id], v]));

	for (const node of list) {
		const parent: R = fullMap.get(node[options.parentId]) as unknown as R;
		if (parent) {
			if (!parent[options.children]) {
				parent[options.children] = [] as any;
			}
			parent[options.children]!.push(node as any);
		} else {
			res.push(node as any);
		}
	}

	return res;
}

export const findPath = (ob: any, key: any) => {
	const path: any[] = [];
	const keyExists = (obj: any) => {
		if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
			return false;
		} else if (obj.hasOwnProperty(key)) {
			return true;
		} else if (Array.isArray(obj)) {
			let parentKey = path.length ? path.pop() : '';

			for (let i = 0; i < obj.length; i++) {
				path.push(`${parentKey}[${i}]`);
				// @ts-ignore
				const result: any = keyExists(obj[i], key);
				if (result) {
					return result;
				}
				path.pop();
			}
		} else {
			for (const k in obj) {
				path.push(k);
				// @ts-ignore
				const result: any = keyExists(obj[k], key);
				if (result) {
					return result;
				}
				path.pop();
			}
		}
		return false;
	};

	keyExists(ob);

	return path.join('.');
};

export const ConstructFolderTreeData = (list: IFolderType[]): TreeData => {
	let newData: TreeData = {
		items: {},
		rootId: 'root',
	};
	let childsIds = list.filter((e) => !e.parent_folder_id).map((e) => e.id);
	console.log(childsIds);

	newData.items['root'] = {
		id: 'root',
		children: childsIds,
		hasChildren: true,
		isExpanded: true,
		data: {},
	};

	list
		.filter((e) => !e.parent_folder_id)
		.map((item) => {
			newData.items[item.id] = {
				id: item.id,
				hasChildren: !!list.filter((e) => list.filter((e1) => e1.parent_folder_id === e.id)),
				children: [],
				data: item,
				isExpanded: false,
			};
		});

	// list.map((item, index) => {
	// 	newData.push({
	// 		data: item,
	// 		id: item.id,
	// 		parent: item.parent_folder_id || '',
	// 		text: '',
	// 	});
	// });

	// list.filter((e) => {
	// 	newData.items[e.id] = {
	// 		id: e.id,
	// 		data: e,
	// 		hasChildren: !!list.filter((e) => e.parent_folder_id === e.id).length,
	// 		children: list.filter((e) => e.parent_folder_id === e.id)?.map((e) => e.id),
	// 		isExpanded: true,
	// 	};
	// });

	console.log('newData', newData);

	return newData;
};
