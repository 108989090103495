import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Block } from './Block';
import { ProductHunt, Txt } from './';
import { IconButton } from './IconButton';
import { Icon } from './Icon';
import { useGoto, useTheme } from '@app/hooks';

interface IFooterProps {
	style?: CSSProperties;
}

export const Footer: React.FC<IFooterProps> = (p) => {
	const { goto } = useGoto();
	const { theme } = useTheme();

	return (
		<Wrapper style={p.style}>
			<Body>
				<Block
					className="row grid min-h-[195px] grid-cols-2 content-center"
					style={{
						gridTemplateColumns: 'repeat(2, 1fr)',
						borderBottom: 'solid 1px ' + theme.solid15,
					}}
				>
					<LeftBlock>
						<Icon name="logo" size={55} color={theme.solid100} />
						<Txt medium size={18} marginTop={2} marginBottom={5}>
							StreamBK
						</Txt>
						<Txt color={theme.solid30}>©️ 2023</Txt>
					</LeftBlock>
					<BtnMainGroup>
						<BtnGroup>
							<HeadingTxt>Product</HeadingTxt>
							<HeadingChildBtn onClick={() => goto('/pricing')}>Pricing</HeadingChildBtn>
							{/* <HeadingChildBtn>Features</HeadingChildBtn> */}
						</BtnGroup>
						<BtnGroup>
							<HeadingTxt>Company</HeadingTxt>
							{/* <HeadingChildBtn>Blog</HeadingChildBtn> */}
							<HeadingChildBtn onClick={() => goto('/why')}>About us</HeadingChildBtn>
						</BtnGroup>
						<BtnGroup>
							<HeadingTxt>Resources</HeadingTxt>
							<HeadingChildBtn onClick={() => goto('/support')}>Support</HeadingChildBtn>
							<HeadingChildBtn onClick={() => window.open('https://discord.gg/pMbpXYFmve', '_blank')}>
								Discord
							</HeadingChildBtn>
						</BtnGroup>
					</BtnMainGroup>
					<ProductHunt style={{ marginBottom: 10 }} />
				</Block>

				<Block minHeight={35} className="row items-end justify-between">
					<LeftUnderPart>
						<HeadingChildBtn onClick={() => goto('/terms')} style={{ marginRight: 15 }}>
							Terms of Service
						</HeadingChildBtn>
						<HeadingChildBtn onClick={() => goto('/privacy')} style={{}}>
							Privacy Policy
						</HeadingChildBtn>
					</LeftUnderPart>

					<SectionBlck>
						<IconBtn
							icon="x"
							size={20}
							noBack
							iconColor={theme.solid30}
							onClick={() => window.open('https://twitter.com/streambkapp', '_blank')}
						/>
						<IconBtn
							icon="discord"
							size={20}
							noBack
							iconColor={theme.solid30}
							onClick={() => window.open('https://discord.gg/pMbpXYFmve', '_blank')}
						/>
						<IconBtn
							icon="linkedin"
							size={20}
							noBack
							iconColor={theme.solid30}
							onClick={() => window.open('https://www.linkedin.com/company/streambk/', '_blank')}
						/>
						{/* <IconBtn
							icon="discord"
							size={20}
							noBack
							iconColor={theme.solid30}
							onClick={() => window.open('https://discord.gg/pMbpXYFmve', '_blank')}
						/> */}
					</SectionBlck>
				</Block>
			</Body>
		</Wrapper>
	);
};

const Wrapper = styled(Block)`
	width: 100%;
	background: ${({ theme }) =>
		theme.name === 'light'
			? 'linear-gradient(0deg, #ffffff 0%, #ebebeb 0.01%, rgba(255, 255, 255, 0) 100%)'
			: 'linear-gradient(0deg, #000000 0%, #151515 0.01%, rgba(0, 0, 0, 0) 100%)'};
	align-items: center;
`;

const Body = styled(Block)`
	z-index: 50;
	max-width: 1000px;
	width: 100%;
	display: flex;
	margin-top: 78px;
	padding-bottom: 94px;
	@media only screen and (max-width: 1100px) {
		width: calc(100% - 50px);
	}
`;

const LeftBlock = styled.div`
	margin-right: 200px;
	@media screen and (max-width: 700px) {
		margin-right: 120px;
	}

	@media screen and (max-width: 600px) {
		margin-right: 50px;
	}
`;

const HeadingTxt = styled(Txt)`
	font-size: 16px;
	margin-bottom: 4px;

	font-weight: 500;
	/* color: ${({ theme }) => theme.solid20} */
`;

const HeadingChildBtn = styled(Txt)`
	user-select: none;
	cursor: pointer;
	width: fit-content;
	margin: 2px 0px;
	color: ${({ theme }) => theme.solid40}!important;
	&:hover {
		opacity: 0.7;
	}
`;

const BtnMainGroup = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-bottom: 70px;
	align-items: baseline;
	width: 100%;
	justify-content: end;

	@media only screen and (max-width: 550px) {
		flex-direction: column;
		align-items: flex-end;
	}
`;

const BtnGroup = styled(Block)`
	margin-right: 50px;
	@media only screen and (max-width: 550px) {
		margin-bottom: 20px;
		flex-grow: 1;
		margin-right: 0px;
		width: 100px;
	}
`;

const IconBtn = styled(IconButton)`
	cursor: pointer;
	opacity: 0.4;
	&:hover {
		opacity: 0.8;
	}
	&:active {
		opacity: 1;
	}
	transition: opacity 0.1s ease-in-out;
	margin-left: 9px;
`;

const SectionBlck = styled.div`
	display: flex;
`;

const LeftUnderPart = styled.div`
	display: flex;
	flex-direction: row;

	p {
		white-space: nowrap;
	}

	@media screen and (max-width: 500px) {
		flex-direction: column;
	}
`;
