import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Block, Icon, Txt } from '.';
import { useTheme } from '@app/hooks';
import { IAccessType } from 'core/types';

interface ISelectBoxProps {
	list: [string, any][];
	value?: string;
	onChange?: (e: IAccessType) => void;
	disabled?: boolean;
}

export const SelectBox = ({ list, value, onChange, disabled }: ISelectBoxProps) => {
	const listRef = useRef<HTMLDivElement>();
	const { theme } = useTheme();
	const [open, setOpen] = useState(false);
	const openRef = useRef(false);

	const onClickHandler = useCallback(
		(e: MouseEvent) => {
			console.log(openRef.current, !!listRef.current);

			if (openRef.current === false) return;
			if (!listRef.current) return;

			if (!listRef.current.contains(e.target as Node)) {
				setOpen(false);
			}
		},
		[listRef, open, openRef],
	);

	useEffect(() => {
		setTimeout(() => (openRef.current = open), 0);
	}, [open]);

	useEffect(() => {
		window.addEventListener('click', (e) => {
			onClickHandler(e);
		});

		return () => {
			window.removeEventListener('click', (e) => {
				onClickHandler(e);
			});
		};
	}, []);

	return (
		<Block style={{ opacity: disabled ? 0.7 : 1 }} width="100%" maxWidth={250}>
			<Box
				className="row items-center"
				onClick={() => {
					if (!disabled) setOpen(true);
				}}
				style={{ justifyContent: 'flex-start' }}
			>
				<Txt color={theme.solid70} medium className="mr-1.5" pl={2}>
					{list.filter((e) => e[1] === value)[0][0]}
				</Txt>
				<Icon size={12} color={theme.solid30} name="angle_left" style={{ transform: 'rotate(-90deg)', marginTop: 3 }} />
			</Box>
			{open && (
				<BlockList ref={listRef}>
					{list.map((item, index) => (
						<ListItem
							className="select-btn"
							key={`id-${index}`}
							onClick={() => {
								setOpen(false);
								if (item[1] === value) return;
								onChange(item[1]);
							}}
						>
							{item[0]}
						</ListItem>
					))}
				</BlockList>
			)}
		</Block>
	);
};

const Box = styled(Block)`
	padding: 4px 5px;
	border-radius: 6px;

	position: relative;
	cursor: pointer;
	user-select: none;
	background-color: ${({ theme }) => theme.solid10};
	max-width: 250px;
	width: 100%;

	&:hover {
		background-color: ${({ theme }) => theme.solid15};
	}
`;

const BlockList = styled(Block)`
	width: 100%;
	max-width: 100px;
	border: none;
	outline: none;
	cursor: pointer;
	position: absolute;
	z-index: 50;
	border-radius: 7px;
	background-color: ${({ theme }) => theme.solid10};
	transform: translateX(calc(-100% + -5px));
`;

const ListItem = styled.p`
	padding: 5px 7px;
	color: ${({ theme }) => theme.solid40};
	font-weight: 600;
	width: calc(100% - 14px);

	&:hover {
		background-color: ${({ theme }) => theme.solid15};
		color: ${({ theme }) => theme.solid80};

		&:nth-child(1) {
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;
		}

		&:nth-last-child(1) {
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
		}
	}
`;
