import React, { CSSProperties } from 'react';
import { Block } from './Block';

interface IProductHuntProps {
	style?: CSSProperties;
}

export const ProductHunt: React.FC<IProductHuntProps> = ({ style }) => {
	return (
		<Block style={style}>
			<a
				href="https://www.producthunt.com/posts/streambk?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-streambk"
				target="_blank"
			>
				<img
					src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=396529&theme=neutral"
					alt="StreamBK - Audio&#0032;collaboration&#0032;Tool | Product Hunt"
					style={{ width: 250, height: 54 }}
					width="250"
					height="54"
				/>
			</a>
		</Block>
	);
};
