import React, { CSSProperties, KeyboardEvent, KeyboardEventHandler, useMemo } from 'react';
import styled from 'styled-components';
import { Txt } from './';
import { useTheme } from '@app/hooks';
import { Icon, Icons } from './Icon';
import { LoadingSpinner } from './LoadingSpinner';

interface ButtonProps {
	text: string;
	textColor?: string;
	color?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
	style?: CSSProperties;
	wide?: boolean;
	small?: boolean;
	gray?: boolean;
	icon?: Icons;
	loading?: boolean;
	primary?: boolean;
	secundair?: boolean;
	className?: string;
}

export const Button = (p: ButtonProps) => {
	const { theme } = useTheme();
	const s = useMemo(
		() => ({
			...(p.wide && { width: '100%' }),
			...(p.gray && { color: '#2b2b2b' }),
			...(p.small && { fontSize: 13, height: 34 }),
			...(p.icon && { flexDirection: 'row', alignContent: 'center', paddingLeft: 15 }),
			...(p.disabled && { pointerEvents: 'none', opacity: 0.6 }),
			...{ backgroundColor: p.color || theme.solid100 },
			...(p.secundair && { backgroundColor: theme.solid40 }),
			...p?.style,
		}),
		[p],
	) as CSSProperties;

	return (
		<ButtonBody
			style={s}
			className={p?.className}
			onClick={(e: any) => {
				p.onClick && p.onClick(e);
			}}
			disabled={p.disabled}
		>
			{p.icon && <Icon name={p.icon} size={20} color={theme.solid} style={{ marginRight: 8 }} />}
			<Txt color={p.textColor || theme.solid}>{p.text}</Txt>
			{p?.loading && <LoadingSpinner color={theme.solid} style={{ marginLeft: 15 }} />}
		</ButtonBody>
	);
};

const ButtonBody = styled.button`
	position: relative;
	padding: 0px 20px;
	cursor: pointer;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: pre;
	border: none;
	border-radius: 8px;

	/* background: ${({ theme }) => theme.solid100}; */

	p {
		font-family: Outfit;
		font-size: 14px;
		/* color: ${({ theme }) => theme.solid}; */
		font-weight: 600;
		user-select: none;
		line-height: 16px;
	}

	&:hover {
		opacity: 0.8;
	}
	&:active {
		opacity: 0.7;
	}
	&:disabled {
		opacity: 0.65;
		cursor: default;
	}

	transition: opacity 0.1s ease-in-out;
`;

export const SmallButton = (p: ButtonProps) => <Button {...p} small />;

interface RoundyProps {
	text: string;
	onClick: () => void;
}

export const Roundy = (p: RoundyProps) => {
	return (
		<RoundyBtn onClick={p.onClick}>
			<p>{p.text}</p>
		</RoundyBtn>
	);
};

const RoundyBtn = styled.div`
	height: 38px;
	border-radius: 50px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	user-select: none;
	cursor: pointer;
	padding: 0px 25px;
	&:hover {
		opacity: 0.7;
	}
	&:active {
		opacity: 0.5;
	}

	p {
		color: black;
		font-family: 'Mona Sans';
		font-weight: bold;
		/* letter-spacing: -0.8px; */
	}
`;

export const Btn = {
	Roundy,
};
