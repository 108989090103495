import React, { useCallback, useState } from 'react';
import toast from 'react-hot-toast';
import { Block, Button, Input, Txt } from '@app/components';
import { IModalProps, ModalComponent } from './ModalComponent';
import { ModalDataTypes, request } from '@app/core';
import { useTheme } from '@app/hooks';

export const ShareSessionInviteModal = (p: IModalProps<ModalDataTypes['ShareSessionInvite']>) => {
	const { theme } = useTheme();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);

	const inviteUser = useCallback(async () => {
		setLoading(true);
		const res = await request('POST', `share/${p.data.share_session_id}/invite`, {
			data: { email: email },
		});
		setLoading(false);

		if (res.data) {
			toast.success(res.message);
			p.onClose();
		} else {
			toast.error(res.message);
			setEmail('');
		}
	}, [email, p?.data]);

	return (
		<ModalComponent {...p}>
			<Block width={350}>
				<Block>
					<Txt marginBottom={10} h3>
						Invite user by email
					</Txt>
					<Input onTextChange={setEmail} value={email} placeholder="someone@email.com..." />
				</Block>
				<Block className="row mt-[15px] justify-center">
					<Button
						disabled={!confirm}
						color={theme.solid10}
						textColor={theme.solid70}
						text={'Cancel'}
						style={{ marginRight: 15 }}
						onClick={() => {
							p.onClose();
						}}
					/>
					<Button color={theme.solid100} text={'Create'} onClick={() => inviteUser()} disabled={!email && !loading} />
				</Block>
			</Block>
		</ModalComponent>
	);
};
