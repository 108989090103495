import { Block, Button, Txt } from '@app/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IModalProps, ModalComponent } from './ModalComponent';
import { useTheme } from '@app/hooks';
import { ModalDataTypes } from '@app/core';

export const WarningModal = (p: IModalProps<ModalDataTypes['Warning']>) => {
	const [confirm, setConfirm] = useState(false);
	const [ne, setNe] = useState(3);
	const neRef = useRef(ne);
	const { theme } = useTheme();

	const pressHandler = (confirmed: boolean) => {
		if (confirmed) p.data.callback();
		p.onClose();
	};

	const run = useCallback(() => {
		let id = setInterval(() => {
			console.log('running');

			if (neRef.current === 1) {
				setConfirm(true);
				clearTimeout(id);
			}
			setNe(neRef.current - 1);
			neRef.current = neRef.current - 1;
		}, 1000);
	}, [ne, neRef.current]);

	useEffect(() => {
		p.data.wait3seconds ? run() : setConfirm(true);
	}, []);

	return (
		<ModalComponent {...p}>
			<Txt bold size={24} center>
				{p.data.headingTitle}
			</Txt>
			<Txt marginTop={5} marginBottom={20} color={theme.solid60} center style={{ maxWidth: 520 }}>
				{p.data.description}
			</Txt>
			<Block className="row w-full items-center">
				<Button
					color={theme.solid100}
					text={p.data.cancelBtnTxt}
					textColor={theme.solid}
					onClick={() => pressHandler(false)}
					className="w-full"
				/>

				<Button
					disabled={!confirm}
					color="#dd5050"
					textColor={'white'}
					text={confirm ? p.data.confirmBtnTxt : `Wait ${ne.toString()} second${ne > 1 ? 's' : ''}`}
					style={{ marginLeft: 15 }}
					onClick={() => pressHandler(true)}
					className="w-full"
				/>
			</Block>
		</ModalComponent>
	);
};
