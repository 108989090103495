import styled from 'styled-components';
import { Txt } from './';

export const UltraWideText = styled(Txt)`
	font-family: Mona Sans Extra;
	letter-spacing: -0.4px;
	font-size: 38;
	font-weight: bolder;
`;

export const BoldWideText = styled(Txt)`
	/* letter-spacing: -3.5%; */
	font-size: 38;
	font-weight: 600;
`;

export const SemiBoldText = styled(Txt)`
	font-family: Mona Sans;
	/* letter-spacing: -3.5%; */
	font-size: 38;
	font-weight: 600;
`;
