import { CSSProperties } from 'react';
import styled from 'styled-components';

interface ISwitchProps {
	value: boolean;
	onChange: (e: boolean) => void;
	disabled?: boolean;
	style?: CSSProperties;
}

export const Switch = (props: ISwitchProps) => {
	return (
		<SwitchBody
			style={props.style}
			onClick={() => {
				props.onChange(!props.value);
			}}
		>
			<Bump active={props.value} style={{ [!props.value ? 'left' : 'right']: 3 }}></Bump>
		</SwitchBody>
	);
};

const SwitchBody = styled.div`
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height: 18px;
	background: ${({ theme }) => theme.solid20};
	display: block;
	border-radius: 100px;
	position: relative;
	padding: 3px;
`;

const Bump = styled.div<{ active: boolean }>`
	height: 18px;
	width: 18px;
	border-radius: 18px;
	position: absolute;
	background-color: ${({ theme, active }) => (active ? theme.solid80 : theme.solid50)};
`;
