import { core } from '@app/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSimpleEvent } from 'simple-core-state';
import styled from 'styled-components';

const PADDING_SPACE = 20;

interface IContextMenuWrapperProps {
	children: React.ReactNode;
}

export const triggerContextMenu = (...data: [any, IMenuItem[]]) => {
	core._events.contextMenu.send(data);
};

interface IMenuData {
	pos: [number, number];
	list: IMenuItem[];
}

interface IMenuItem {
	text?: string;
	textColor?: string;
	onClick: () => void;
}

export const ContextMenuWrapper: React.FC<IContextMenuWrapperProps> = (p) => {
	const [active, setActive] = useState<IMenuData[]>([]);
	const activeRef = useRef(active);
	const menuRef = useRef<HTMLDivElement>(null);

	useSimpleEvent(core._events.contextMenu, (data) => {
		// Get the current mouse location
		const loc = getMouseLocation(data[0]);

		// Push the new menu data into the list
		setTimeout(() => {
			setActive((x) => {
				let a = [...x];
				a.push({ list: data[1], pos: loc });
				activeRef.current = a;
				return a;
			});
		}, 0);

		// adjustments
		setTimeout(() => adjustment(), 5);
	});

	const getMouseLocation = (event: React.MouseEvent): [number, number] => {
		let Y = event.pageY;
		let X = event.pageX;
		return [Y, X];
	};

	const adjustment = useCallback(() => {
		if (menuRef.current === null) return;

		const { left, right, width, top } = menuRef.current.getBoundingClientRect();

		// Check if the menuref has enough space, otherwise we need to remove the box to its proper position
		if (right > window.window.innerWidth) {
			menuRef.current.style.left = window.window.innerWidth - (width + PADDING_SPACE) + 'px';
			menuRef.current.style.top = top + PADDING_SPACE + 'px';
		}

		// show the menu for the user viewport
		menuRef.current.style.opacity = '1';
	}, [menuRef]);

	const eventClickListener = useCallback(
		(e: MouseEvent) => {
			console.log('clicking x');
			if (!menuRef.current || !activeRef.current.length) return;
			console.log('clicking');

			// Check if we are clicking outside the menu
			// console.log(menuRef.current?.childNodes);
			// console.log(e);

			menuRef.current?.childNodes.forEach((item) => {
				if (!item.contains(menuRef.current)) {
					setActive([]);
					activeRef.current = [];
				}
			});
		},
		[menuRef, active, activeRef]
	);

	useEffect(() => {
		window.addEventListener('click', eventClickListener);
		return () => window.removeEventListener('click', eventClickListener);
	}, []);

	return (
		<>
			{!!active?.length && (
				<MenuBody ref={menuRef} style={{ top: active[0].pos[0], left: active[0].pos[1] }}>
					{active[0].list.map((item, index) => {
						return (
							<MenuItem
								key={index}
								style={{ color: item.textColor }}
								onClick={(e) => {
									// active handler
									item.onClick();

									// Close the menu
									setActive([]);
								}}
							>
								{item.text || '-'}
							</MenuItem>
						);
					})}
				</MenuBody>
			)}
			{p.children}
		</>
	);
};

const MenuBody = styled.div`
	position: absolute;
	opacity: 0;
	padding: 7px;
	background-color: ${({ theme }) => theme.solid};
	border-radius: 10px;
	border: solid 1px ${({ theme }) => theme.solid10};
	box-shadow: 0 0 13px 1px ${({ theme }) => (theme.name === 'light' ? '#76767624' : '#4a4a4a24')};
	z-index: 50;
	min-width: 100px;
`;

const MenuItem = styled.p`
	color: ${({ theme }) => theme.text};
	font-weight: 500;
	padding: 5px 15px;
	padding-left: 6px;
	cursor: pointer;
	white-space: nowrap;
	border-radius: 6px;
	user-select: none;
	transition: background-color 0.1s ease-in-out;

	&:hover {
		background-color: ${({ theme }) => theme.solid10};
	}
`;
