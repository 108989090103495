import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface ILoadingSpinnerProps {
	color?: string;
	size?: number;
	style?: CSSProperties;
}

export const LoadingSpinner = (p: ILoadingSpinnerProps) => {
	const size = p?.size || 20;

	return <LoadingSpinnerBody style={p.style} c={p.color} size={size}></LoadingSpinnerBody>;
};

const LoadingSpinnerBody = styled.div<{ c?: string; size: number }>`
	@keyframes spinner {
		to {
			transform: rotate(360deg);
		}
	}

	width: ${({ size }) => size}px;
	height: ${({ size }) => size}px;
	border-radius: 50%;
	border-top: 2px solid ${({ c, theme }) => c || theme.solid100};
	border-right: 2px solid transparent;
	animation: spinner 0.6s linear infinite;
`;
