import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useTheme } from '@app/hooks';
import { HomepageHeader } from 'components/header/HomepageHeader';

// CodeSplitting
const AppView = lazy(() => import('../pages/app/index'));
const HomePage = lazy(() => import('../pages/index'));
const PricingPage = lazy(() => import('../pages/pricing'));
const SupportPage = lazy(() => import('../pages/support'));
const TermsPage = lazy(() => import('../pages/terms'));
const WhyPage = lazy(() => import('../pages/why'));
const PrivacyPolicyPage = lazy(() => import('../pages/privacy'));
const VerifyLoginPage = lazy(() => import('../pages/login/verify'));
const LoginPage = lazy(() => import('../pages/login'));
const SignupPage = lazy(() => import('../pages/signup'));
const ResetPasswordPage = lazy(() => import('../pages/login/reset'));
const NotfoundPage = lazy(() => import('../pages/*/index'));
const DemoPage = lazy(() => import('../pages/demo/index'));
const SharePage = lazy(() => import('../pages/share/index'));

const routesList = [
	{
		path: '/',
		element: <HomePage />,
	},
	{
		path: '/privacy',
		element: <PrivacyPolicyPage />,
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		path: '/signup',
		element: <SignupPage />,
	},
	{
		path: '/login/verify',
		element: <VerifyLoginPage />,
	},
	{
		path: '/login/reset',
		element: <ResetPasswordPage />,
	},
	{
		path: '/app/*',
		element: <AppView />,
	},
	{
		path: '/share/:share_code',
		element: <SharePage />,
	},
	{
		path: '/pricing',
		element: <PricingPage />,
	},
	{
		path: '/why',
		element: <WhyPage />,
	},
	{
		path: '/terms',
		element: <TermsPage />,
	},
	{
		path: '/support',
		element: <SupportPage />,
	},
	{
		path: '/demo',
		element: <DemoPage />,
	},
	{
		path: '/*',
		element: <NotfoundPage />,
	},
];

export const RootRouter = () => {
	const { theme } = useTheme();

	return (
		<div className="h-full overflow-x-hidden overflow-y-hidden" style={{ backgroundColor: theme.solid }}>
			<Routes>
				{routesList.map((item, index) => (
					<Route path={item.path} element={<Suspense>{item.element}</Suspense>} key={index} />
				))}
			</Routes>
		</div>
	);
};
