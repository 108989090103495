import React, { useCallback, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, Block, ProgressBar, Input, IconButton, Txt } from '@app/components';
import { core, IAudioFile, ModalDataTypes, request } from '@app/core';
import { IModalProps, ModalComponent } from './ModalComponent';
import styled from 'styled-components';
import { useSimple } from 'simple-core-state';
import { useTheme } from '@app/hooks';

export const UploadAudioModal = (p: IModalProps<ModalDataTypes['UploadAudio']>) => {
	const { theme } = useTheme();
	const [uploadLoading, setUploadLoading] = useState(false);
	const [previewAudioFile, setPreviewAudioFile] = useState<any>();
	const [uploadPercentage, setUploadPercentage] = useState<number>(0);
	const [audioFile, setAudioFile] = useState<any>();
	const [audioBuffer, setAudioBuff] = useState<ArrayBuffer>();
	const [fileName, setFileName] = useState('');
	const [originalFileName, setOriginalFileName] = useState('');
	const [uploadStats, setUploadStats] = useState({
		steps: 0,
		step: 0,
	});

	const fileInputRef = useRef<HTMLInputElement>(null);
	const audioElRef = useRef<HTMLAudioElement>(null);

	const appState = useSimple(core.app_state);
	const folderItems = useSimple(core.folder_items);

	const reset = () => {
		setUploadLoading(false);
		setAudioFile(null);
		setPreviewAudioFile(null);
		setFileName('');
	};

	const createChunks = (file: ArrayBuffer, cSize: number /* cSize should be byte 1024*1 = 1KB */) => {
		let startPointer = 0;
		let endPointer = file.byteLength;
		let chunks = [];
		while (startPointer < endPointer) {
			let newStartPointer = startPointer + cSize;
			chunks.push(file.slice(startPointer, newStartPointer));
			startPointer = newStartPointer;
		}
		return chunks;
	};

	const uploadAudioFile = useCallback(async () => {
		if (!audioFile || !audioBuffer) return;
		setUploadLoading(true);
		const uploadSessionID = crypto.randomUUID();

		if (!audioBuffer) return;

		// Chunks of 25MB = 26214400
		// Chunks of 5MB = 5242880
		// Chunks of 1MB = 1048576
		const byteSizeChunk = 5242880 * 2;
		const chunks = createChunks(audioBuffer, byteSizeChunk);

		let step = 0;

		setUploadStats({ step: 0, steps: chunks.length });

		console.log('uploading ' + chunks.length + ' chunks');

		// Loop trough the chunks and it should always run no matter what
		for (const chunk of chunks) {
			step = step + 1;

			setUploadStats((e) => ({ ...e, step }));

			const chunkFd = new FormData();
			const isLastStep = chunks.length === step;
			const BlobChunk = new Blob([chunk]);

			chunkFd.append('file', BlobChunk);
			chunkFd.append('display_name', fileName);
			chunkFd.append('total_steps', chunks.length.toString());
			chunkFd.append('size', BlobChunk.size.toString());
			chunkFd.append('step', step.toString());
			chunkFd.append('id', uploadSessionID);

			// send the chunk to the server
			const res = await request<IAudioFile>('POST', 'audio/upload', {
				data: chunkFd,
				headers: {
					'Content-Type': 'multi-part/form-data',
				},

				// Update the percentage after we uploaded the file
				onUploadProgress: (e) => {
					if (!e.total || !e.loaded) return;

					// calc the percentage of the current chunk
					const chunkPercentage = e.loaded / e.total;

					console.log('chunkPercentage =>', chunkPercentage * 100);

					const totalProgress = (step * (chunkPercentage * 100)) / chunks.length;

					console.log('totalProgress =>', totalProgress);

					setUploadPercentage(totalProgress);
				},
			});

			console.log('chunk files', res);

			if (res.data) {
				// If its the last step, then close the modal or finish
				if (isLastStep) {
					toast.success('File has been uploaded');
					// add file to list
					const a = [...folderItems.root];
					a.push(res.data);

					core.folder_items.patch({ root: a });

					p.onClose();
					reset();
				}
			} else {
				toast.error(res.message);
				setUploadLoading(false);
				return;
			}
		}
	}, [audioFile, fileName, audioElRef, appState, audioBuffer]);

	return (
		<ModalComponent {...p}>
			<Block width={350}>
				<Block height="100%">
					<Txt center h1 marginBottom={12}>
						Upload a file
					</Txt>
					<input
						ref={fileInputRef}
						type="file"
						accept="audio/x-m4a, audio/wav, audio/mp3, audio/wma, audio/aac, audio/flac"
						// accept="audio/x-m4a, audio/wav, audio/mp3, audio/wma, audio/aac, audio/flac, video/mp4"
						multiple={false}
						style={{
							color: 'white',
							display: 'none',
						}}
						onChange={async (e) => {
							if (e.target.files) {
								setAudioBuff(await e.target.files[0].arrayBuffer());
								setFileName(e.target.files[0].name.split('.')[0]);
								setOriginalFileName(e.target.files[0].name);
								setAudioFile(e.target.files[0]);
								setPreviewAudioFile(URL.createObjectURL(e.target.files[0]));
							}
						}}
					/>

					{previewAudioFile && (
						<Block className="row items-center">
							<Block
								className="h-[35px] items-center justify-center"
								width="calc(100% - 40px)"
								color={theme.solid80}
								borderRadius={6}
							>
								<Txt color={theme.solid} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
									{originalFileName}
								</Txt>
							</Block>
							<IconButton
								icon="plus"
								style={{ transform: 'rotate(45deg)', marginLeft: 10 }}
								size={28}
								backgroundColor="#e65555"
								iconSize={16}
								iconColor={theme.solid}
								onClick={() => reset()}
								disabled={uploadLoading}
							/>
						</Block>
					)}
					<Block className="row mb-[10px] mt-[9px]">
						<Txt color={theme.solid50} marginRight={4}>
							Allowed file types:
						</Txt>
						<Txt medium color={theme.solid80}>
							mp3, m4a, aac, flac, wav
						</Txt>
					</Block>
					{previewAudioFile && <audio ref={audioElRef} src={previewAudioFile} controls style={{ display: 'none' }} />}
					{!previewAudioFile && (
						<Button text="Select File" color={theme.solid100} onClick={() => fileInputRef.current?.click()} />
					)}

					<Input
						placeholder="Name"
						onTextChange={setFileName}
						value={fileName}
						disabled={uploadLoading}
						style={{
							width: 'calc(100% - 24px)',
							marginTop: 15,
							opacity: uploadLoading ? 0.7 : 1,
							pointerEvents: uploadLoading ? 'none' : 'unset',
						}}
					/>
					{uploadLoading && uploadStats.steps > 1 && (
						<Txt center marginTop={5}>
							{uploadStats.step} / {uploadStats.steps}
						</Txt>
					)}
					{uploadLoading && (
						<ProgressBar percentage={uploadPercentage} style={{ width: 'calc(100% - 8px)', marginTop: 10 }} />
					)}
				</Block>
				<Block className="row w-full justify-between pt-[30px]">
					<Button text="Close" color={theme.solid100} onClick={() => p.onClose()} disabled={uploadLoading} />
					<Button
						text="Upload"
						color={theme.solid100}
						disabled={uploadLoading || !audioFile}
						onClick={() => uploadAudioFile()}
					/>
				</Block>
			</Block>
		</ModalComponent>
	);
};

const FileDropArea = styled.div`
	height: 110px;
	border-radius: 12px;
	border: dotted 2px #292929;
	:hover {
		border: dotted 2px #888888;
	}
	transition: border 0.2s ease-in-out;
`;
