import 'unfonts.css';
import './index.css';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import 'react-tooltip/dist/react-tooltip.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { core } from './core';
import { initializeSentry } from 'sentry.client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (import.meta.env.MODE === 'production') {
	initializeSentry();
	root.render(
		// <React.StrictMode>
		<App />,
		// </React.StrictMode>
	);
} else {
	core.cdn_url.set('http://localhost:3020/.cache/');
	root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
