import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { useTheme } from '../hooks/useTheme';

interface TextButtonProps {
	text: string;
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	color?: string;
	size?: number;
	center?: boolean;
	disabled?: boolean;
	style?: CSSProperties;
	bold?: boolean;
}

export const TextButton = (p: TextButtonProps) => {
	const { theme } = useTheme();
	return (
		<TextButtonBody
			className={p?.className}
			dis={p?.disabled}
			onClick={(e) => p?.onClick && !p?.disabled && p?.onClick(e)}
			style={p.style}
		>
			<p
				style={{
					color: p.color || theme.text,
					fontSize: p.size || 14,
					fontWeight: p.bold ? '600' : '400',
					textAlign: p?.center ? 'center' : 'left',
				}}
			>
				{p.text}
			</p>
		</TextButtonBody>
	);
};

const TextButtonBody = styled.div<{ dis?: boolean }>`
	p {
		cursor: pointer;
		user-select: none;
		width: fit-content;
		&:hover {
			opacity: 0.6;
		}
		&:active {
			opacity: 0.5;
		}
	}

	${({ dis }) => dis && 'opacity: 0.5;pointer-events:none;'}
`;
