import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useSimple } from 'simple-core-state';
import { core, Themes } from '../core';

export const useTheme = () => {
	const currentTheme = useSimple(core.theme);
	const [systemThemeEnabled, setUseSystemTheme] = useState<boolean>(false);

	const isDarkMode = useCallback(() => {
		return currentTheme === 'dark';
	}, [currentTheme]);

	const updateUseSystemTheme = (v: boolean) => {
		setUseSystemTheme(v);
	};

	const toggleTheme = (setThemeTypeParam?: 'light' | 'dark') => {
		if (setThemeTypeParam) {
			core.theme.set(setThemeTypeParam);
		} else {
			core.theme.set(currentTheme === 'light' ? 'dark' : 'light');
		}
	};

	const theme = useMemo(() => Themes[currentTheme], [currentTheme]);

	return {
		isDarkMode: isDarkMode(),
		toggleTheme,
		theme,
		updateUseSystemTheme,
		systemThemeEnabled,
		currentTheme,
	};
};

// This is specific for styled components so we can use the theme object inside a styled component anotation such like `color: ${p => p.theme.color}`
export const StyledThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const currentTheme = useSimple(core.theme);

	return <ThemeProvider theme={Themes[currentTheme]}>{children}</ThemeProvider>;
};
